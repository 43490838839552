import {BaseLayout} from '~/modules/Layout/components/BaseLayout';
import CollectionsHomePage from '~/modules/Collections/components/CollectionsHomePage';
import {HeadersFunction} from '@shopify/remix-oxygen';
import {SeoHandleFunction} from '@shopify/hydrogen';
import {getLoader} from '~/modules/Product/loaders/collectionsLoader';
import {getStoreTitle} from '~/modules/Product/utils/getStoreTitle';

export const headers: HeadersFunction = () => ({
  'Cache-Control': 'max-age=300, s-maxage=900',
});

export const loader = getLoader(true);

const seo: SeoHandleFunction<typeof loader> = ({data, params}) => {
  const expertName = data.expertName || params.expertName;
  const title = getStoreTitle(data.storeName);
  const description = data.description;
  const domain = `https://www.wayward.com/${expertName}`;
  const image = data?.hero || '';

  return {
    title,
    description,
    'og:title': title,
    'og:description': description,
    'og:type': 'article',
    'og:url': domain,
    'og:image': image,
    'twitter:image': image,
    'twitter:card': 'summary_large_image',
    'twitter:domain': domain,
    'twitter:url': domain,
    'twitter:title': title,
    'twitter:description': description,
  };
};

export const handle = {
  seo,
};

export default () => (
  <BaseLayout>
    <CollectionsHomePage />
  </BaseLayout>
);
