import {FC} from 'react';

interface WaveMobileProps {
  fill?: string;
  width?: number;
  height?: number;
  className: string;
}

export const WaveMobile: FC<WaveMobileProps> = ({
  width = 206,
  height = 483,
  fill = '#5C332C',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      preserveAspectRatio="none"
      className={className}
      viewBox="0 0 393 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M197.851 21.5725C109.426 59.0386 31.2026 24.0213 -1.62478e-06 1.82939L4.67712e-06 39L393 39L393 18.3582C364.794 3.81876 286.276 -15.8935 197.851 21.5725Z"
        fill={fill}
      />
    </svg>
  );
};
