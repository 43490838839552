import {BackgroundShapes, HeaderTypes} from '~/modules/Appearance/types';
import {ThemeV2, getDefaultThemeV2} from '~/modules/Expert/data';

import {Container} from './Container';
import {DownSlope} from '~/modules/Appearance/images/DownSlope';
import {DownSlopeMobile} from '~/modules/Appearance/images/DownSlopeMobile';
import {FC} from 'react';
import {Heading} from '~/modules/Common/components/Heading';
import {Text} from '~/modules/Common/components/Text';
import {UpSlope} from '~/modules/Appearance/images/UpSlope';
import {UpSlopeMobile} from '~/modules/Appearance/images/UpSlopeMobile';
import {Wave} from '~/modules/Appearance/images/Wave';
import {WaveMobile} from '~/modules/Appearance/images/WaveMobile';
import {useExpert} from '~/modules/Common/hooks/useExpert';

interface HeroV2Props {
  theme?: ThemeV2;
}

const Shapes = (props: {
  fill?: string;
  width?: number;
  height?: number;
  className: string;
  type: BackgroundShapes;
}) => {
  const components = {
    [BackgroundShapes.wave]: Wave,
    [BackgroundShapes.downSlope]: DownSlope,
    [BackgroundShapes.upSlope]: UpSlope,
  };

  const Component = components[props.type];
  if (!Component) return null;

  return <Component {...props} />;
};

const ShapesMobile = (props: {
  fill?: string;
  width?: number;
  height?: number;
  className: string;
  type: BackgroundShapes;
}) => {
  const components = {
    [BackgroundShapes.wave]: WaveMobile,
    [BackgroundShapes.downSlope]: DownSlopeMobile,
    [BackgroundShapes.upSlope]: UpSlopeMobile,
  };

  const Component = components[props.type];
  if (!Component) return null;

  return <Component {...props} />;
};

export const HeroV2: FC<HeroV2Props> = ({theme}) => {
  const {expertName} = useExpert();
  if (!theme) {
    theme = getDefaultThemeV2(expertName);
  }
  const backgroundBlockColor = theme.backgroundBlockColor || '#171717';
  const backgroundBlockShape =
    theme.backgroundBlockShape || BackgroundShapes.wave;
  const textColor = theme.backgroundBlockTextColor || '#fff';
  const Shape = Shapes({
    fill: backgroundBlockColor,
    type: backgroundBlockShape,
    className: 'absolute bottom-0 -right-[55px] top-0 h-full ',
    width: 55,
  });
  const ShapeMobile = ShapesMobile({
    fill: backgroundBlockColor,
    type: backgroundBlockShape,
    className: 'absolute -top-[34px] left-0 right-0 w-full',
    height: 35,
  });

  return theme.headerType === HeaderTypes.header ? (
    <div style={{backgroundColor: backgroundBlockColor}}>
      <Container>
        {theme.backgroundImage && (
          <link href={theme.backgroundImage} rel="preload" as="image" />
        )}
        <div
          className="flex flex-col md:flex-row-reverse item-center flex-nowrap md:h-[480px]"
          style={{backgroundColor: backgroundBlockColor}}
        >
          <div
            className={`md:basis-[41.66%] flex-auto md:flex-none aspect-[5/4] bg-cover`}
            style={{
              backgroundImage: `url(${theme.backgroundImage})`,
              minWidth: '1px',
            }}
          />
          <div
            className="md:basis-[58.33%] relative z-[2] flex-auto flex flex-col items-start justify-center py-6 px-4 md:px-16 text-center"
            style={{backgroundColor: backgroundBlockColor}}
          >
            <div className="hidden md:block">{Shape}</div>
            <div className="md:hidden">{ShapeMobile}</div>
            <Heading
              size="headlineLg"
              as="h1"
              className="block text-white w-full"
              style={{color: textColor}}
            >
              {theme.title}
            </Heading>
            {theme.subtext && (
              <Text
                as="p"
                className="block text-white text-md w-full font-sans md:text-[18px]"
                style={{color: textColor}}
                size="copy"
              >
                {theme.subtext}
              </Text>
            )}
          </div>
        </div>
      </Container>
    </div>
  ) : null;
};
