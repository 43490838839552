import {FC} from 'react';

interface WaveProps {
  fill?: string;
  width?: number;
  height?: number;
  className: string;
}

export const Wave: FC<WaveProps> = ({
  width = 206,
  height = 483,
  fill = '#5C332C',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 55 640"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      className={className}
    >
      <path
        d="M24.562 322.201C-28.301 178.2 21.1069 50.8134 52.4188 0H0V640L29.0974 640C49.6119 594.067 77.4251 466.201 24.562 322.201Z"
        fill={fill}
      />
    </svg>
  );
};
