import {Collection, Product} from '~/gql/generated';
import {FC, PropsWithChildren} from 'react';
import {Heading, Link} from '~/modules';

import {CollectionCarousel} from '../../Collections/components/CollectionCarousel';
import {ProductsProvider} from '../context/ProductsContext';
import {Product as ShopifyProduct} from '@shopify/hydrogen/storefront-api-types';
import {useCollectionLink} from '~/modules/Common/hooks/useCollectionLink';

export const HomePageCollection: FC<{
  collection: Collection;
  shopifyProducts: ShopifyProduct[];
  apiProducts: Product[];
  title: string;
}> = ({collection, apiProducts, shopifyProducts, title}) => {
  const collectionLink = useCollectionLink(collection);

  if (!collection || !collection.displayInHomePage || !apiProducts.length) {
    return null;
  }

  return (
    <HomePageCollectionContainer
      collectionLink={collectionLink}
      collection={collection}
      title={title}
    >
      <ProductsProvider
        shopifyProducts={shopifyProducts.nodes}
        apiProducts={apiProducts}
      >
        <CollectionCarousel />
      </ProductsProvider>
    </HomePageCollectionContainer>
  );
};

export const HomePageCollectionContainer: FC<
  PropsWithChildren<{
    collection: Collection;
    collectionLink?: string;
    title: string;
  }>
> = ({collection, collectionLink, title, children}) => {
  return (
    <>
      <HomePageCarouselTitle
        title={title || collection.name}
        collectionLink={collectionLink}
        productNum={collection.productNum || 0}
      />
      <div className="-ml-2 -mr-4 xl:mr-0">{children}</div>
    </>
  );
};

export const HomePageCarouselTitle: FC<{
  title: string;
  collectionLink?: string;
  productNum?: number;
}> = ({title, collectionLink, productNum}) => {
  const TitleComponent = (
    <Heading as="h2" width="wide" className="block" size="headlineMd">
      {title}
    </Heading>
  );

  return (
    <div className="flex flex-row items-end justify-between xl:px-4">
      <div>
        {collectionLink && (
          <Link to={collectionLink} prefetch={'intent'}>
            {TitleComponent}
          </Link>
        )}
        {!collectionLink && TitleComponent}
        {collectionLink && productNum && (
          <Heading as="h3" width="wide" className="block" size="copy">
            {productNum} product
            {productNum > 1 ? 's' : ''}
          </Heading>
        )}
      </div>
      {collectionLink && (
        <Link
          to={collectionLink}
          className="underline whitespace-nowrap"
          prefetch={'intent'}
        >
          View all
        </Link>
      )}
    </div>
  );
};
