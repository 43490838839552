import {Collection} from '~/gql/generated';
import {FC} from 'react';
import {HomePageCarouselTitle} from '~/modules/Product/components/HomePageCollection';
import {PostCard} from './PostCard';

interface PostsCarouselProps {
  posts: Collection[];
}

export const PostsCarousel: FC<PostsCarouselProps> = ({posts}) => {
  return (
    <div>
      <HomePageCarouselTitle title="My Posts" collectionLink="/posts" />
      <div className={`swimlane gap-2 !p-0 hiddenScroll md:overflow-x-auto `}>
        {posts.map((post, index) => (
          <PostCard
            className="block snap-start my-2 w-[230px] md:w-[250px] lg:w-[298px] rounded-md overflow-hidden cursor-pointer auto-rows-auto relative p-2 rounded-lg border-[1px] border-solid border-transparent hover:border-codGray hover:shadow-storefront "
            key={post.id}
            post={post}
            visibleByDefault={index < 4}
            linkPrefix="/posts"
            isHoverEnabled={true}
          />
        ))}
      </div>
    </div>
  );
};
