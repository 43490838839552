import {Collection, Product} from '~/gql/generated';
import {FC, useCallback, useEffect, useState} from 'react';

import {CollectionGrid} from '~/modules/Collections/components/CollectionGrid';
import {HomePageCollectionContainer} from './HomePageCollection';
import InfiniteScroll from 'react-infinite-scroll-component';
import {PortalSpinner} from '~/modules/Common/components/PortalSpinner';
import {ProductsProvider} from '../context/ProductsContext';
import {Product as ShopifyProduct} from '@shopify/hydrogen/storefront-api-types';
import {useExpertLink} from '~/modules/Common/hooks/useExpertLink';
import {useQueryClient} from '@tanstack/react-query';

interface HomeAllProductsCollectionProps {
  initialProducts: {products: Product[]; shopifyProducts: ShopifyProduct[]};
  initialCollection?: Collection;
}

export const HomeAllProductsCollection: FC<HomeAllProductsCollectionProps> = ({
  initialProducts,
  initialCollection,
}) => {
  const [collection, setCollection] = useState<Collection | undefined>(
    initialCollection,
  );
  const [allProducts, setAllProducts] = useState<Product[]>(
    initialProducts?.products || [],
  );
  const [shopifyProducts, setShopifyProducts] = useState<ShopifyProduct[]>(
    initialProducts?.shopifyProducts?.nodes || [],
  );
  const client = useQueryClient();
  const [page, setPage] = useState(initialProducts ? 2 : 1);
  const [hasMore, setHasMore] = useState(true);
  const allLink = useExpertLink('/api/all');
  const loadProductsLink = useExpertLink(
    `/api/products/${page}/25?&filterVisible=true`,
  );

  useEffect(() => {
    if (!initialProducts) {
      (async () => {
        const response = await fetch(allLink, {
          method: 'get',
        });
        const result = await response.json();
        setCollection(result?.allCollection);
      })();
    }
  }, [client, allLink, initialProducts]);

  const loadAllProducts = useCallback(async () => {
    const response = await fetch(loadProductsLink, {
      method: 'post',
    });
    const {
      products,
      shopifyProducts: dataShopify,
      maxProducts,
    } = await response.json();
    const nextProducts = [...allProducts, ...products];
    setAllProducts(nextProducts);
    setShopifyProducts([...shopifyProducts, ...(dataShopify?.nodes || [])]);
    setPage(page + 1);
    setHasMore(maxProducts > nextProducts.length && products.length > 0);
  }, [allProducts, loadProductsLink, page, shopifyProducts]);

  useEffect(() => {
    if (!initialProducts) {
      loadAllProducts();
    }
  }, []);

  return allProducts && allProducts?.length > 0 ? (
    <HomePageCollectionContainer
      collection={collection}
      title={initialProducts ? 'Featured Products' : 'All Products'}
    >
      <ProductsProvider
        shopifyProducts={shopifyProducts || []}
        apiProducts={allProducts || []}
      >
        <InfiniteScroll
          dataLength={allProducts.length}
          next={loadAllProducts}
          hasMore={hasMore}
          loader={
            <div className="center-row justify-center py-2">
              <PortalSpinner />
            </div>
          }
          hasChildren={true}
          scrollThreshold={0.7}
        >
          <CollectionGrid />
        </InfiniteScroll>
      </ProductsProvider>
    </HomePageCollectionContainer>
  ) : null;
};
