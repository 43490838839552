import {FC} from 'react';
import {Grid} from '~/modules/Layout/components/Grid';
import {ProductCard} from '~/modules/Product/components/ProductCard';
import {useProducts} from '~/modules/Product/hooks/useProducts';

export const CollectionGrid: FC = () => {
  const {products} = useProducts();

  return (
    <Grid layout="products" data-test="product-grid" className="!gap-2">
      {products.map((product, index) => (
        <ProductCard
          key={product.id}
          product={product}
          visibleByDefault={index < 4}
        />
      ))}
    </Grid>
  );
};
