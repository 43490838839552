import {FC} from 'react';

interface DownSlopeMobileProps {
  fill?: string;
  width?: number;
  height?: number;
  className: string;
}

export const DownSlopeMobile: FC<DownSlopeMobileProps> = ({
  width = 206,
  height = 483,
  fill = '#5C332C',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 393 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      className={className}
    >
      <path
        d="M1.26763e-06 1.89491e-06L393 28.2946L393 29L0 29L1.26763e-06 1.89491e-06Z"
        fill={fill}
      />
    </svg>
  );
};
