import {FC} from 'react';

interface DownSlopeProps {
  fill?: string;
  width?: number;
  height?: number;
  className: string;
}

export const DownSlope: FC<DownSlopeProps> = ({
  width = 206,
  height = 483,
  fill = '#5C332C',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 640"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="none"
      className={className}
    >
      <path d="M31 0L0.705353 640H0L0 0H31Z" fill={fill} />
    </svg>
  );
};
