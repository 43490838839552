import {FC} from 'react';
import {ProductCard} from '~/modules/Product/components/ProductCard';
import {useProducts} from '~/modules/Product/hooks/useProducts';

export const CollectionCarousel: FC = () => {
  const {products} = useProducts();

  return (
    <div className={`swimlane gap-2 !p-0 hiddenScroll md:overflow-x-auto `}>
      {products.map((product, index) => (
        <ProductCard
          key={product.id}
          product={product}
          visibleByDefault={index < 4}
          containerClassName="block snap-start my-2 w-[230px] md:w-[250px] lg:w-[298px] rounded-md overflow-hidden cursor-pointer auto-rows-auto"
        />
      ))}
    </div>
  );
};
