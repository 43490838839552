import {Await, useLoaderData} from '@remix-run/react';
import {Suspense, useCallback, useMemo, useState} from 'react';

import {Container} from '~/modules/Layout/components/Container';
import {Hero} from '~/modules';
import {HeroV2} from '~/modules/Layout/components/HeroV2';
import {HomeAllProductsCollection} from '~/modules/Product/components/HomeAllProductsCollection';
import {HomePageCollection} from '~/modules/Product/components/HomePageCollection';
import InfiniteScroll from 'react-infinite-scroll-component';
import {MilkDrunkCallout} from '~/modules/Common/components/MilkDrunkCallout';
import {PortalSpinner} from '~/modules/Common/components/PortalSpinner';
import {PostsCarousel} from '~/modules/Posts/components/PostsCarousel';
import {getStoreTitle} from '~/modules/Product/utils/getStoreTitle';
import {useExpert} from '~/modules/Common/hooks/useExpert';
import {useExpertLink} from '~/modules/Common/hooks/useExpertLink';

export default function CollectionsHomePage() {
  const data = useLoaderData();
  const {store, theme} = useExpert();
  const title =
    (theme?.v2 ? theme.v2.title : getStoreTitle(store.name)) ||
    'Some products I love that you will too!';

  const subtitle = theme?.v2
    ? theme.v2.subtext
    : store?.subtitleText ||
      'Shop my personal top picks - exclusive discounts already applied!';

  const initialCollections = useMemo(() => {
    const res = [];
    for (let i = 0; i < data.collectionCount; i++) {
      res.push(data[`collection-${i}`]);
    }
    return res;
  }, [data]);
  const [additionalCollections, setAdditionalCollections] = useState([]);
  const [page, setPage] = useState(2);
  const loadMoreLink = useExpertLink('/api/collections');
  const loadCollections = useCallback(async () => {
    const response = await fetch(loadMoreLink, {
      method: 'POST',
      body: JSON.stringify({page, limit: 3}),
    });
    const result = await response.json();
    if (result.collections) {
      setAdditionalCollections([
        ...additionalCollections,
        ...result.collections,
      ]);
      setPage(page + 1);
    }
    return result;
  }, [additionalCollections, loadMoreLink, page]);
  const hasMore =
    initialCollections.length + additionalCollections.length <
    data.maxCollections;

  return (
    <>
      <MilkDrunkCallout />
      {!theme?.v2 && !theme?.store?.isDefault && (
        <Hero
          title={title}
          subtitle={subtitle}
          image={{src: store.hero!, alt: ''}}
          mobileImage={
            store.heroMobile ? {src: store.heroMobile!, alt: ''} : undefined
          }
          imageClassName={store.heroClassName}
        />
      )}
      {(theme?.v2 || theme?.store?.isDefault) && <HeroV2 theme={theme.v2} />}

      <Container className="flex flex-col gap-2 p-4 xl:px-0 ">
        <Suspense fallback={<></>} key={'posts'}>
          <Await resolve={data.posts}>
            {(posts) =>
              posts.posts?.length > 0 && <PostsCarousel posts={posts.posts} />
            }
          </Await>
        </Suspense>
        {(initialCollections || []).map((collectionPromise, index) => (
          <Suspense fallback={<></>} key={index}>
            <Await resolve={collectionPromise}>
              {({shopifyProducts, apiProducts, collection}) =>
                collection?.displayInHomePage ? (
                  <HomePageCollection
                    shopifyProducts={shopifyProducts}
                    apiProducts={apiProducts}
                    collection={collection}
                    key={collection.id}
                    title={''}
                  />
                ) : null
              }
            </Await>
          </Suspense>
        ))}
        {data.maxCollections > 3 && (
          <InfiniteScroll
            dataLength={additionalCollections.length}
            next={loadCollections}
            hasMore={hasMore}
            loader={
              <div className="center-row justify-center py-2">
                <PortalSpinner size="sm" />
              </div>
            }
            hasChildren={true}
            scrollThreshold={0.7}
          >
            {additionalCollections.map(
              ({shopifyProducts, apiProducts, collection}) =>
                collection.displayInHomePage ? (
                  <HomePageCollection
                    shopifyProducts={shopifyProducts}
                    apiProducts={apiProducts}
                    collection={collection}
                    key={collection.id}
                    title={''}
                  />
                ) : null,
            )}
          </InfiniteScroll>
        )}
        {!hasMore && (
          <Suspense
            fallback={
              <div className="flex justify-start items-start pt-4">
                <PortalSpinner size="lg" />
              </div>
            }
          >
            <Await resolve={data?.allProducts}>
              {(allProducts) => (
                <HomeAllProductsCollection
                  initialProducts={allProducts}
                  initialCollection={data?.allCollection}
                />
              )}
            </Await>
          </Suspense>
        )}
      </Container>
    </>
  );
}
