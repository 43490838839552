import {FC} from 'react';

interface UpSlopeMobileProps {
  fill?: string;
  width?: number;
  height?: number;
  className: string;
}

export const UpSlopeMobile: FC<UpSlopeMobileProps> = ({
  width = 206,
  height = 483,
  fill = '#5C332C',
  className = '',
}) => {
  return (
    <svg
      width={width}
      height={height}
      preserveAspectRatio="none"
      className={className}
      viewBox="0 0 393 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M393 1.89491e-06L-3.08319e-08 28.2946L0 29L393 29L393 1.89491e-06Z"
        fill={fill}
      />
    </svg>
  );
};
